import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import Layout from '../components/Layout';
import { PageSubtitle } from '../components/TextComponents';

const NewComponent = ({data}) => {
  return (
    <Layout noHero>
      <section className="pb-24">
        <div className="flex flex-col gap-10 justify-center items-center pt-20">
          <PageSubtitle>Ingresa para obtener más información</PageSubtitle>
          <BackgroundImage
            // Spread bgImage into BackgroundImage:
            fluid={data.roomBackground.childImageSharp.fluid}
            preserveStackingContext
            alt="Room"
            className="flex items-end z-10 w-80 h-80 rounded-t-full overflow-hidden"
          >
            <div className="bg-brown-700 w-full p-4">
              <h2 className="text-white-default text-lg font-semibold">Habitaciones</h2>
            </div>
          </BackgroundImage>

          <BackgroundImage
            // Spread bgImage into BackgroundImage:
            fluid={data.photosBackground.childImageSharp.fluid}
            preserveStackingContext
            alt="Room"
            className="flex items-end z-10 w-80 h-80 rounded-t-full overflow-hidden"
          >
            <div className="bg-brown-300 w-full p-4">
              <h2 className="text-white-default text-lg font-semibold">Fotografía</h2>
            </div>
          </BackgroundImage>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query{
    roomBackground: file(relativePath: { eq: "roomsPhotos/IMG_7260.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    photosBackground: file(relativePath: { eq: "homeHeroPhotos/casa_mucha_banner_07.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default NewComponent;